import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  TopToolbar,
  CreateButton,
  ExportButton,
  SearchInput,
  usePermissions,
} from "react-admin";

import {
  acCanEdit,
  acCanCreate,
  acCanDelete,
} from "../components/accessControl";

const ListActions = () => (
  <TopToolbar>
    <CreateButton />
  </TopToolbar>
);

const brandFilters = [<SearchInput source="q" alwaysOn />];

const BrandList = () => {
  const { isLoading, permissions } = usePermissions();

  if (isLoading) return <></>;

  const accessControl = {
    edit: acCanEdit("brands", permissions),
    create: acCanCreate("brands", permissions),
    delete: acCanDelete("brands", permissions),
  };

  return (
    <List
      sort={{ field: "name", order: "ASC" }}
      perPage={25}
      filters={brandFilters}
      actions={accessControl.create ? <ListActions /> : false}
    >
      <Datagrid
        rowClick={accessControl.edit ? "edit" : false}
        bulkActionButtons={accessControl.delete ? true : false}
      >
        <TextField label="Name" source="name" />
      </Datagrid>
    </List>
  );
};

export default BrandList;
