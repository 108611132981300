import * as React from "react";
import { UserMenu, useUserMenu, Logout } from "react-admin";

import { Link } from "react-router-dom";
import { MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import { IoMdSettings } from "react-icons/io";

const ProfileMenuItem = React.forwardRef((props, ref) => {
  // We are not using MenuItemLink so we retrieve the onClose function from the UserContext
  const { onClose } = useUserMenu();
  return (
    <MenuItem
      ref={ref}
      // It's important to pass the props to allow Material UI to manage the keyboard navigation
      {...props}
      component={Link}
      to="/profile"
      onClick={onClose}
    >
      <ListItemIcon>
        <IoMdSettings />
      </ListItemIcon>
      <ListItemText>Profile</ListItemText>
    </MenuItem>
  );
});

const MyUserMenu = () => (
  <UserMenu>
    <ProfileMenuItem />
    <Logout />
  </UserMenu>
);

export default MyUserMenu;
