import { useGetIdentity } from "react-admin";
import ProfileEdit from "./ProfileEdit";

const Profile = () => {
  const { data, isLoading, error } = useGetIdentity();
  const { id } = data;

  return isLoading ? <></> : <ProfileEdit id={id} />;
};

export default Profile;
