import * as React from "react";
import { useState } from "react";
import { usePermissions } from "react-admin";
import Box from "@mui/material/Box";

import { RiAdminFill } from "react-icons/ri";

import { DashboardMenuItem, MenuItemLink, useSidebarState } from "react-admin";

import users from "../users";
import roles from "../roles";
import pages from "../pages";
import accesscontrol from "../accesscontrol";
import brands from "../brands";
import serials from "../serials";
import doctypes from "../doctypes";

import modeldoc from "../modeldoc";
import failurecode from "../failurecode";

import SubMenu from "./SubMenu";

import { acCanShow } from "../components/accessControl";

const Menu = ({ dense = false }) => {
  const { isLoading, permissions } = usePermissions();
  const [state, setState] = useState({
    menuAdmin: true,
  });
  const [open] = useSidebarState();

  const menuAdminList = [
    {
      key: "sub_admin",
      type: "submenu",
      handleToggle: "menuAdmin",
      state: state.menuAdmin,
      name: "Admin",
      icon: <RiAdminFill />,
      item: [
        {
          key: "admin_users",
          to: "/users",
          primaryText: users.label,
          leftIcon: <users.icon />,
          show: acCanShow("users", permissions),
        },
        {
          key: "admin_roles",
          to: "/roles",
          primaryText: roles.label,
          leftIcon: <roles.icon />,
          show: acCanShow("roles", permissions),
        },
        {
          key: "admin_pages",
          to: "/pages",
          primaryText: pages.label,
          leftIcon: <pages.icon />,
          show: acCanShow("pages", permissions),
        },
        {
          key: "admin_accesscontrol",
          to: "/accesscontrol",
          primaryText: accesscontrol.label,
          leftIcon: <accesscontrol.icon />,
          show: acCanShow("accesscontrol", permissions),
        },
        {
          key: "brands",
          to: "/brands",
          primaryText: brands.label,
          leftIcon: <brands.icon />,
          show: acCanShow("brands", permissions),
        },
        {
          key: "serials",
          to: "/serials",
          primaryText: serials.label,
          leftIcon: <serials.icon />,
          show: acCanShow("serials", permissions),
        },
        {
          key: "doctypes",
          to: "/doctypes",
          primaryText: doctypes.label,
          leftIcon: <doctypes.icon />,
          show: acCanShow("doctypes", permissions),
        },
      ],
    },
  ];

  const menuList = [
    {
      key: "docs",
      to: "/docs",
      primaryText: modeldoc.label,
      leftIcon: <modeldoc.icon />,
      show: acCanShow("docs", permissions),
    },
    {
      key: "failures",
      to: "/failures",
      primaryText: failurecode.label,
      leftIcon: <failurecode.icon />,
      show: acCanShow("failures", permissions),
    },
  ];

  const createMenu = (menuList, permissions) => {
    let itemCount = false;

    menuList.forEach((m) => {
      if (m.type === "submenu" && m.item && Array.isArray(m.item)) {
        itemCount = m.item.some((i) => i.show);
      }
    });

    return menuList.map((e) =>
      e.type && e.type === "submenu" ? (
        !itemCount ? (
          <></>
        ) : (
          <SubMenu
            key={e.key}
            handleToggle={() => handleToggle(e.handleToggle)}
            isOpen={e.state}
            name={e.name}
            icon={e.icon}
            dense={dense}
          >
            {e.item &&
              e.item.map((se) =>
                se.show ? (
                  <MenuItemLink
                    key={se.key}
                    to={se.to}
                    state={{ _scrollToTop: true }}
                    primaryText={se.primaryText}
                    leftIcon={se.leftIcon}
                    dense={dense}
                  />
                ) : (
                  <></>
                )
              )}
          </SubMenu>
        )
      ) : e.show ? (
        <MenuItemLink
          key={e.key}
          to={e.to}
          state={{ _scrollToTop: true }}
          primaryText={e.primaryText}
          leftIcon={e.leftIcon}
          dense={dense}
        />
      ) : (
        <></>
      )
    );
  };

  const handleToggle = (menu) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return isLoading ? (
    <>Loading</>
  ) : (
    <Box
      sx={{
        width: open ? 200 : 50,
        marginTop: 1,
        marginBottom: 1,
        transition: (theme) =>
          theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
    >
      {/* Hide the Dashboard menu here
      <DashboardMenuItem />
      */}
      {createMenu(menuList, permissions)}
      {createMenu(menuAdminList, permissions)}
    </Box>
  );
};

export default Menu;
