import decodeJwt from "jwt-decode";

import inMemoryJWTManager from "./inMemoryJwt";
import { addRefreshAuthToAuthProvider } from "react-admin";

const refreshAuth = async () => {
  const token = inMemoryJWTManager.getToken();

  // Access token missing or expired?
  if (!token || decodeJwt(token).exp - 5 < new Date().getTime() / 1000) {
    // Fetch a new access token
    const request = new Request(
      process.env.REACT_APP_DATA_SOURCE + "/authen/refresh",
      {
        method: "GET",
        headers: new Headers({ "Content-Type": "application/json" }),
      }
    );

    const response = await fetch(request);
    if (response.status < 200 || response.status >= 300) {
      return Promise.reject(response);
    }
    const data = await response.json();
    inMemoryJWTManager.setToken(data.token);
    inMemoryJWTManager.setData(decodeJwt(data.token));
    return Promise.resolve();
  }
  return Promise.resolve();
};

const myAuthProvider = {
  login: async ({ email, password }) => {
    const request = new Request(
      process.env.REACT_APP_DATA_SOURCE + "/authen/login",
      {
        method: "POST",
        body: JSON.stringify({
          email: email,
          password: password,
        }),
        headers: new Headers({ "Content-Type": "application/json" }),
      }
    );

    const response = await fetch(request);
    if (response.status < 200 || response.status >= 300) {
      return Promise.reject(response);
    }
    const data = await response.json();
    // Save the authentication token in local storage or a cookie
    inMemoryJWTManager.setToken(data.token);
    inMemoryJWTManager.setData(decodeJwt(data.token));
    return Promise.resolve();
  },
  logout: async () => {
    const request = new Request(
      process.env.REACT_APP_DATA_SOURCE + "/authen/logout",
      {
        method: "GET",
        headers: new Headers({ "Content-Type": "application/json" }),
      }
    );
    const response = await fetch(request);
    if (response.status < 200 || response.status >= 300) {
      return Promise.reject(response);
    }

    inMemoryJWTManager.eraseData();
    inMemoryJWTManager.eraseData();
    return Promise.resolve();
  },
  checkError: (error) => {
    if (
      error &&
      error.status &&
      (error.status === 401 || error.status === 403)
    ) {
      // Handle unauthorized and forbidden errors
      // Redirect the user to the login page
      return Promise.reject();
    }
    // Handle other errors
    return Promise.resolve();
  },
  checkAuth: () => {
    return inMemoryJWTManager.getToken() ? Promise.resolve() : Promise.reject();
  },
  getPermissions: () => {
    const authData = inMemoryJWTManager.getData();
    if (
      authData !== undefined &&
      authData !== null &&
      authData.role &&
      authData.access
    ) {
      return Promise.resolve({
        role: authData.role,
        access: authData.access,
      });
    }
    return Promise.reject();
  },
  getIdentity: () => {
    const authData = inMemoryJWTManager.getData();
    if (authData !== undefined && authData !== null && authData.id) {
      return Promise.resolve({
        id: authData.id,
        fullName:
          authData.firstname && authData.lastname
            ? authData.firstname + " " + authData.lastname
            : "",
      });
    }
    return Promise.reject();
  },
};

const authProvider = addRefreshAuthToAuthProvider(myAuthProvider, refreshAuth);

export { authProvider, refreshAuth };
