import * as React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  useUpdate,
  useRedirect,
  useNotify,
  usePermissions,
  Toolbar,
  SaveButton,
} from "react-admin";
import { Typography } from "@mui/material";

import { acCanDelete } from "../components/accessControl";

const { validateSerialNumber } = require("./serialValidate");

const SerialEdit = () => {
  const { isLoading, permissions } = usePermissions();
  const [update] = useUpdate();
  const redirect = useRedirect();
  const notify = useNotify();

  if (isLoading) return <></>;

  const accessControl = {
    delete: acCanDelete("serials", permissions),
  };

  const handleSubmit = (data) => {
    let newSerial = { ...data };

    update(
      "serials",
      {
        id: newSerial.id,
        data: newSerial,
      },
      {
        onSuccess: (data) => {
          notify("Update success", { type: "success" });
          redirect("/serials");
        },
        onError: (error) => {
          notify("Failed to update model", { type: "error" });
        },
      }
    );
  };

  const SerialsEditToolbar = () => (
    <Toolbar>
      <SaveButton />
    </Toolbar>
  );

  return (
    <Edit title="Edit Serial">
      <SimpleForm
        warnWhenUnsavedChanges
        onSubmit={handleSubmit}
        sx={{ maxWidth: 500 }}
        toolbar={accessControl.delete ? <Toolbar /> : <SerialsEditToolbar />}
      >
        <Typography variant="h4" gutterBottom>
          Edit Model
        </Typography>
        <ReferenceInput source="brand_id" reference="brands">
          <SelectInput label="Brand" optionText="name" isRequired fullWidth />
        </ReferenceInput>
        <TextInput
          label="Model"
          source="number"
          autoFocus
          isRequired
          validate={validateSerialNumber}
          fullWidth
        />
      </SimpleForm>
    </Edit>
  );
};

export default SerialEdit;
