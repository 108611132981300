import { SiSteelseries } from "react-icons/si";

import SerialList from "./SerialList";
import SerialEdit from "./SerialEdit";
import SerialCreate from "./SerialCreate";

const resource = {
  label: "Models",
  list: SerialList,
  create: SerialCreate,
  edit: SerialEdit,
  icon: SiSteelseries,
};

export default resource;
