import * as React from "react";
import {
  SimpleForm,
  PasswordInput,
  TextInput,
  Toolbar,
  SaveButton,
  useUpdate,
  useRedirect,
  useNotify,
} from "react-admin";
import { Card, Box, Typography } from "@mui/material";
import { useEditController } from "react-admin";

const {
  validateFirstName,
  validateLastName,
  validateEmail,
  validateEditPassword,
} = require("../users/userValidate");

const ProfileEdit = (props) => {
  const { id: userId } = props;
  const { record, isLoading } = useEditController({
    resource: "users",
    id: userId,
  });
  const [update] = useUpdate();
  const redirect = useRedirect();
  const notify = useNotify();

  if (isLoading) return null;

  const handleSubmit = (data) => {
    let newData = { ...data };
    if (newData.passwd) {
      newData.password = newData.passwd;
    } else {
      delete newData.password;
    }
    delete newData.passwd;
    delete newData.rpasswd;
    update(
      "users",
      { id: newData.id, data: newData },
      {
        onSuccess: (data) => {
          notify("Update success", { type: "success" });
          redirect("/");
        },
        onError: (error) => {
          notify("Update failed", { type: "error" });
        },
      }
    );
  };

  const validateRPassword = (value, allValues) => {
    if (!value && !allValues.passwd) {
      return undefined;
    }
    if (value !== allValues.passwd) {
      return "Password not match";
    }
    return undefined;
  };

  const ProfileEditToolbar = (props) => (
    <Toolbar
      {...props}
      sx={{ display: "flex", justifyContent: "space-between" }}
    >
      <SaveButton />
    </Toolbar>
  );

  return (
    <Card>
      <SimpleForm
        record={record}
        warnWhenUnsavedChanges
        toolbar={<ProfileEditToolbar />}
        onSubmit={handleSubmit}
        sx={{ maxWidth: 500 }}
      >
        <Typography variant="h4" gutterBottom>
          Edit Profile
        </Typography>
        <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <TextInput
              label="First Name"
              source="firstname"
              validate={validateFirstName}
              isRequired
              fullWidth
            />
          </Box>
          <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
            <TextInput
              label="Last Name"
              source="lastname"
              validate={validateLastName}
              isRequired
              fullWidth
            />
          </Box>
        </Box>
        <TextInput
          label="Email"
          source="email"
          validate={validateEmail}
          isRequired
          fullWidth
        />
        <PasswordInput
          label="Password"
          source="passwd"
          validate={validateEditPassword}
          fullWidth
        />
        <PasswordInput
          label="Repeat Password"
          source="rpasswd"
          validate={[validateEditPassword, validateRPassword]}
          fullWidth
        />
      </SimpleForm>
    </Card>
  );
};

export default ProfileEdit;
