import { IoSettings } from "react-icons/io5";

import AccessControlList from "./AccessControlList";

const resource = {
  label: "Access Control",
  list: AccessControlList,
  icon: IoSettings,
};

export default resource;
