import { MdBrandingWatermark } from "react-icons/md";

import BrandList from "./BrandList";
import BrandEdit from "./BrandEdit";
import BrandCreate from "./BrandCreate";

const resource = {
  label: "Brands",
  list: BrandList,
  create: BrandCreate,
  edit: BrandEdit,
  icon: MdBrandingWatermark,
};

export default resource;
