import * as React from "react";
import {
  Create,
  PasswordInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useCreate,
  useNotify,
  useRedirect,
} from "react-admin";
import { Box, Typography } from "@mui/material";

const { validateName, validateCode } = require("./pageValidate");

const PageCreate = () => {
  const [create] = useCreate();
  const notify = useNotify();
  const redirect = useRedirect();

  const handleSubmit = (data) => {
    let page = { ...data };
    create(
      "pages",
      { data: page },
      {
        onSuccess: (data) => {
          notify("Page is created", { type: "success" });
          redirect("/pages");
        },
        onError: (error) => {
          notify("Failed to create page", { type: "error" });
        },
      }
    );
  };

  const validateRPassword = (value, allValues) => {
    if (value !== allValues.password) return "Password not match.";
    return undefined;
  };

  return (
    <Create title="Create page">
      <SimpleForm
        warnWhenUnsavedChanges
        onSubmit={handleSubmit}
        sx={{ maxWidth: 500 }}
      >
        <Typography variant="h4" gutterBottom>
          Create Page
        </Typography>
        <TextInput
          label="Name"
          source="name"
          validate={validateName}
          isRequired
          fullWidth
        />
        <TextInput
          label="Code"
          source="code"
          validate={validateCode}
          isRequired
          fullWidth
        />
      </SimpleForm>
    </Create>
  );
};

export default PageCreate;
