import { RiPagesFill } from "react-icons/ri";

import PageList from "./PageList";
import PageEdit from "./PageEdit";
import PageCreate from "./PageCreate";

const resource = {
  label: "Pages List",
  list: PageList,
  create: PageCreate,
  edit: PageEdit,
  icon: RiPagesFill,
};

export default resource;
