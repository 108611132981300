import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  TopToolbar,
  CreateButton,
  ExportButton,
  SearchInput,
  usePermissions,
} from "react-admin";

import {
  acCanEdit,
  acCanCreate,
  acCanDelete,
} from "../components/accessControl";

const ListActions = () => (
  <TopToolbar>
    <CreateButton />
  </TopToolbar>
);

const modelFilters = [<SearchInput source="q" alwaysOn />];

const SerialList = () => {
  const { isLoading, permissions } = usePermissions();

  if (isLoading) return <></>;

  const accessControl = {
    edit: acCanEdit("serials", permissions),
    create: acCanCreate("serials", permissions),
    delete: acCanDelete("serials", permissions),
  };

  return (
    <List
      sort={{ field: "number", order: "ASC" }}
      perPage={25}
      filters={modelFilters}
      actions={accessControl.create ? <ListActions /> : false}
    >
      <Datagrid
        rowClick={accessControl.edit ? "edit" : false}
        bulkActionButtons={accessControl.delete ? true : false}
      >
        <ReferenceField
          label="Brand"
          source="brand_id"
          reference="brands"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField label="Model" source="number" />
      </Datagrid>
    </List>
  );
};

export default SerialList;
