import * as React from "react";
import {
  Edit,
  SimpleForm,
  PasswordInput,
  ReferenceArrayInput,
  SelectArrayInput,
  TextInput,
  useUpdate,
  useRedirect,
  useNotify,
  required,
  usePermissions,
  Toolbar,
  SaveButton,
} from "react-admin";
import { Box, Typography } from "@mui/material";

import { acCanDelete } from "../components/accessControl";

const {
  validateFirstName,
  validateLastName,
  validateEmail,
  validateEditPassword,
} = require("./userValidate");

const UserEdit = () => {
  const { isLoading, permissions } = usePermissions();
  const [update] = useUpdate();
  const redirect = useRedirect();
  const notify = useNotify();

  if (isLoading) return <></>;

  const accessControl = {
    delete: acCanDelete("users", permissions),
  };

  const handleSubmit = (data) => {
    let newData = { ...data };

    if (newData.passwd) {
      newData.password = newData.passwd;
    } else {
      delete newData.password;
    }
    delete newData.passwd;

    update(
      "users",
      {
        id: newData.id,
        data: newData,
      },
      {
        onSuccess: (data) => {
          notify("Update success", { type: "success" });
          redirect("/users");
        },
        onError: (error) => {
          notify("Update failed", { type: "error" });
        },
      }
    );
  };

  const validateRPassword = (value, allValues) => {
    if (!value && !allValues.passwd) {
      return undefined;
    }
    if (value !== allValues.passwd) {
      return "Password not match";
    }
    return undefined;
  };

  const UserEditToolbar = () => (
    <Toolbar>
      <SaveButton />
    </Toolbar>
  );

  return (
    <Edit title="Edit User">
      <SimpleForm
        warnWhenUnsavedChanges
        onSubmit={handleSubmit}
        sx={{ maxWidth: 500 }}
        toolbar={accessControl.delete ? <Toolbar /> : <UserEditToolbar />}
      >
        <Typography variant="h4" gutterBottom>
          Edit User
        </Typography>
        <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <TextInput
              label="First Name"
              source="firstname"
              validate={validateFirstName}
              autoFocus
              isRequired
              fullWidth
            />
          </Box>
          <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
            <TextInput
              label="Last Name"
              source="lastname"
              validate={validateLastName}
              isRequired
              fullWidth
            />
          </Box>
        </Box>
        <TextInput
          label="Email"
          source="email"
          validate={validateEmail}
          isRequired
          fullWidth
        />
        <PasswordInput
          label="Password"
          source="passwd"
          validate={validateEditPassword}
          fullWidth
        />
        <PasswordInput
          label="Repeat Password"
          source="rpasswd"
          validate={[validateEditPassword, validateRPassword]}
          fullWidth
        />
        <ReferenceArrayInput source="role" reference="roles">
          <SelectArrayInput
            label="Role"
            optionText="name"
            validate={[required()]}
            fullWidth
          />
        </ReferenceArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export default UserEdit;
