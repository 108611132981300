import React, { useState } from "react";
import { useCreate, useCreateSuggestionContext } from "react-admin";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";

const CreateBrandsOnSelectCreate = () => {
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  const [name, setName] = useState(filter || "");
  const [create] = useCreate();

  const handleSubmit = (event) => {
    event.preventDefault();
    create(
      "brands",
      {
        data: {
          name: name,
        },
      },
      {
        onSuccess: (data) => {
          setName("");
          onCreate(data);
        },
      }
    );
  };

  return (
    <Dialog open onClose={onCancel}>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            label="New brand name"
            value={name}
            onChange={(event) => setName(event.target.value)}
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">Save</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateBrandsOnSelectCreate;
