const inMemoryJWTManager = () => {
  let authToken = null;
  let authData = null;

  const getToken = () => authToken;
  const getData = () => authData;

  const setToken = (token) => {
    authToken = token;
    return true;
  };

  const setData = (data) => {
    authData = data;
    return true;
  };

  const ereaseToken = () => {
    authToken = null;
    return true;
  };

  const eraseData = () => {
    authData = null;
    return true;
  };

  return {
    ereaseToken,
    getToken,
    setToken,
    eraseData,
    getData,
    setData,
  };
};

export default inMemoryJWTManager();
