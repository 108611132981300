import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  useCreate,
  useNotify,
  useRedirect,
} from "react-admin";
import { Box, Typography } from "@mui/material";

const { validateSerialNumber } = require("./serialValidate");

const SerialCreate = () => {
  const [create] = useCreate();
  const notify = useNotify();
  const redirect = useRedirect();

  const handleSubmit = (data) => {
    let serial = { ...data };

    create(
      "serials",
      { data: serial },
      {
        onSuccess: (data) => {
          notify("Model is created", { type: "success" });
          redirect("/serials");
        },
        onError: (error) => {
          notify("Failed to create Model", { type: "error" });
        },
      }
    );
  };

  return (
    <Create title="Create Serial">
      <SimpleForm
        warnWhenUnsavedChanges
        onSubmit={handleSubmit}
        sx={{ maxWidth: 500 }}
      >
        <Typography variant="h4" gutterBottom>
          Create Model
        </Typography>
        <ReferenceInput source="brand_id" reference="brands">
          <SelectInput label="Brand" optionText="name" isRequired fullWidth />
        </ReferenceInput>
        <TextInput
          label="Model"
          source="number"
          autoFocus
          isRequired
          validate={validateSerialNumber}
          fullWidth
        />
      </SimpleForm>
    </Create>
  );
};

export default SerialCreate;
