import React from "react";
import { FileField, useRecordContext, Button } from "react-admin";

import { IoCloudDownloadOutline } from "react-icons/io5";

const MyFileField = (props) => {
  const { source, title, ...rest } = props;
  const record = useRecordContext(props);

  const handleDownload = () => {
    const downloadLink = document.createElement("a");
    downloadLink.href = record[source];
    downloadLink.download = record[title];
    downloadLink.click();
  };

  return (
    <>
      <FileField source={source} title={title} {...rest} />
      <Button onClick={handleDownload}>
        <IoCloudDownloadOutline />
      </Button>
    </>
  );
};

export default MyFileField;
