import * as React from "react";

import logo from "../images/logo.png";

const Logo = () => {
  return (
    <img
      src={logo}
      alt="EcMach Managmenet System"
      style={{ width: 167, height: 43 }}
    />
  );
};

export default Logo;
