import { FaFileCode } from "react-icons/fa6";

import FailureCodeList from "./FailureCodeList";
import FailureCodeCreate from "./FailureCodeCreate";

const resource = {
  label: "Failure Code",
  list: FailureCodeList,
  create: FailureCodeCreate,
  icon: FaFileCode,
};

export default resource;
