import { MdTypeSpecimen } from "react-icons/md";

import DoctypeList from "./DoctypeList";
import DoctypeEdit from "./DoctypeEdit";
import DoctypeCreate from "./DoctypeCreate";

const resource = {
  label: "Doc Types",
  list: DoctypeList,
  create: DoctypeCreate,
  edit: DoctypeEdit,
  icon: MdTypeSpecimen,
};

export default resource;
