import * as React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  useUpdate,
  useRedirect,
  useNotify,
  usePermissions,
  Toolbar,
  SaveButton,
} from "react-admin";
import { Typography } from "@mui/material";

import { acCanDelete } from "../components/accessControl";

const {
  valiateDoctypeName,
  validateDoctypeName,
} = require("./doctypeValidate");

const DoctypeEdit = () => {
  const { isLoading, permissions } = usePermissions();
  const [update] = useUpdate();
  const redirect = useRedirect();
  const notify = useNotify();

  if (isLoading) return <></>;

  const accessControl = {
    delete: acCanDelete("doctypes", permissions),
  };

  const handleSubmit = (data) => {
    let newDoctype = { ...data };

    update(
      "doctypes",
      {
        id: newDoctype.id,
        data: newDoctype,
      },
      {
        onSuccess: (data) => {
          notify("Update success", { type: "success" });
          redirect("/doctypes");
        },
        onError: (error) => {
          notify("Failed to update doc type", { type: "error" });
        },
      }
    );
  };

  const DoctypeEditToolbar = () => (
    <Toolbar>
      <SaveButton />
    </Toolbar>
  );

  return (
    <Edit title="Edit Doc Type">
      <SimpleForm
        warnWhenUnsavedChanges
        onSubmit={handleSubmit}
        sx={{ maxWidth: 500 }}
        toolbar={accessControl.delete ? <Toolbar /> : <DoctypeEditToolbar />}
      >
        <Typography variant="h4" gutterBottom>
          Edit Doc Type
        </Typography>
        <TextInput
          label="Name"
          source="name"
          autoFocus
          isRequired
          validate={validateDoctypeName}
          fullWidth
        />
      </SimpleForm>
    </Edit>
  );
};

export default DoctypeEdit;
