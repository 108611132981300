import React, { useEffect, useState } from "react";
import {
  ListBase,
  Datagrid,
  TextField,
  useGetList,
  useDataProvider,
  useNotify,
  useRecordContext,
  SimpleForm,
  SaveButton,
  usePermissions,
  Title,
} from "react-admin";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { Checkbox } from "@mui/material";

import { acCanEdit } from "../components/accessControl";

const { accessControlChoice } = require("./accessControlType");

const AccessControlList = () => {
  /* AccessRights object format:
  {
    PageID: {
      RoleID: {read:true, edit:true, create:true, delete:true}
    },
    PageID: {
      RoleID: {read:true, edit:true, create:false, delete:true}
    }
  }
  */
  const [accessControl, setAccessControl] = useState({});
  const [enableSave, setEnableSave] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const { data: pages, isLoading: pageLoading } = useGetList("pages", {
    pagination: { page: 1, perPage: -1 },
    sort: { field: "name", order: "ASC" },
  });
  const { data: roles, isLoading: roleLoading } = useGetList("roles", {
    pagination: { page: 1, perPage: -1 },
    sort: { field: "name", order: "ASC" },
  });
  const { data: acs, isLoading: acLoading } = useGetList("accesscontrol", {
    pagination: { page: 1, perPage: -1 },
    sort: { field: "id", order: "ASC" },
  });
  const { isLoading: permissionsLoading, permissions } = usePermissions();

  useEffect(() => {
    function parseAcList(acList) {
      let newAC = {};
      acList.forEach((i) => {
        let access = {};
        accessControlChoice.forEach((choice) => {
          access[choice.id] = i.access.includes(choice.id);
        });

        newAC = {
          ...newAC,
          [i.page_id]: {
            ...(newAC[i.page_id] || {}),
            [i.role_id]: access,
          },
        };
      });
      return newAC;
    }

    if (acs) {
      const newAcs = parseAcList(acs);
      setAccessControl(newAcs);
    }
  }, [acs]);

  if (pageLoading || roleLoading || acLoading || permissionsLoading)
    return <></>;

  const allowEdit = acCanEdit("accesscontrol", permissions);

  const handleAccessControlChange = (pageId, roleId, accessId, checked) => {
    setEnableSave(true);
    setAccessControl((prevState) => ({
      ...prevState,
      [pageId]: {
        ...(prevState[pageId] || {}),
        [roleId]: {
          ...(prevState[pageId]?.[roleId] || {}),
          [accessId]: checked,
          // eslint-disable-next-line
          ["changed"]: true,
        },
      },
    }));
  };

  const handleSave = async () => {
    const submitAccess = [];
    Object.keys(accessControl).forEach((pageId) => {
      Object.keys(accessControl[pageId]).forEach((roleId) => {
        const access = [];
        accessControlChoice.forEach((choice) => {
          if (accessControl[pageId][roleId][choice.id]) access.push(choice.id);
        });

        submitAccess.push({
          page_id: pageId,
          role_id: roleId,
          access: access,
          changed: accessControl[pageId][roleId].changed,
        });
      });
    });

    const changedAccess = submitAccess.filter((sa) => {
      if (sa.changed) {
        delete sa.changed;
        return true;
      }
      return false;
    });

    try {
      if (changedAccess.length) {
        const createPromises = changedAccess.map((ca) =>
          dataProvider.create("accesscontrol", { data: ca })
        );
        await Promise.all(createPromises);
      }
      notify("Access control saved successfully", { type: "success" });
    } catch (error) {
      notify("Error saving access control", { type: "error" });
    }
  };

  const AccessControlCheckbox = ({ pageId, accessId }) => {
    const _record = useRecordContext();
    if (!_record) return <div />;
    return (
      <Checkbox
        key={`accb-${pageId}-${_record.id}-${accessId}`}
        checked={accessControl[pageId]?.[_record.id]?.[accessId] || false}
        disabled={allowEdit ? false : true}
        onChange={(event, checked) =>
          handleAccessControlChange(pageId, _record.id, accessId, checked)
        }
      />
    );
  };

  return (
    <>
      <Title title="Access Control" />

      <div>
        <SimpleForm toolbar={false} sx={{ width: "100%" }}>
          {pages.map((page) => (
            <Accordion key={page.id} sx={{ width: "100%" }}>
              <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
                <TextField source="name" record={page} />
              </AccordionSummary>
              <AccordionDetails>
                <ListBase basePath={`/pages/${page.id}`} resource="roles">
                  <Datagrid bulkActionButtons={false}>
                    <TextField label="Role" source="name" />
                    {accessControlChoice.map((access) => (
                      <AccessControlCheckbox
                        key={`acgp-${page.id}-${access.label}`}
                        pageId={page.id}
                        accessId={access.id}
                        label={access.label}
                      />
                    ))}
                  </Datagrid>
                </ListBase>
              </AccordionDetails>
            </Accordion>
          ))}
          <SaveButton
            alwaysEnable={enableSave}
            onClick={handleSave}
            sx={{ marginTop: 2 }}
          />
        </SimpleForm>
      </div>
    </>
  );
};

export default AccessControlList;
