import * as React from "react";
import { CheckForApplicationUpdate, Layout } from "react-admin";
import AppBar from "./AppBar";
import Menu from "./Menu";

const myLayout = ({ children, ...props }) => (
  <Layout {...props} appBar={AppBar} menu={Menu}>
    {children}
    <CheckForApplicationUpdate />
  </Layout>
);

export default myLayout;
