import React, { useState } from "react";
import {
  useCreate,
  useNotify,
  useRedirect,
  Create,
  SimpleForm,
  SelectInput,
  FileInput,
  TextInput,
  required,
  ReferenceInput,
  FileField,
  useDataProvider,
  usePermissions,
} from "react-admin";

import BrandModelFilterSelectInput from "../components/BrandModelFilterSelectInput";

import { ToggleButton, ToggleButtonGroup } from "@mui/material";

import { fileUploadWithRefresh } from "../components/FileUploadWithRefresh";
import CreateBrandsOnSelectCreate from "../brands/CreateBrandsOnSelectCreate";
import CreateSerialsOnSelectCreate from "../serials/CreateSerialsOnSelectCreate";

import { parse } from "papaparse";
import { acCanCreate } from "../components/accessControl";

const {
  validateFailureCode,
  validateUserCode,
  validateFailureText,
  validateComponent,
  validateCategory,
} = require("./failurecodeValidate");

const FailureCodeSingleForm = (props) => {
  const { isLoading, permissions } = usePermissions();
  const [selectedBrand, setSelectedBrand] = useState("");

  const [create] = useCreate();
  const notify = useNotify();
  const redirect = useRedirect();

  if (isLoading) return <></>;

  const accessControl = {
    brandCreate: acCanCreate("brands", permissions),
    modelCreate: acCanCreate("serials", permissions),
  };

  const handleBrandChange = (e) => {
    if (e !== null && typeof e === "object") setSelectedBrand(e.target.value);
    else setSelectedBrand(e);
  };

  const handleSubmit = async (data) => {
    let submitData = { ...data };

    // Upload file
    const doc = submitData.document;
    const formData = new FormData();

    formData.append("file", doc.rawFile);
    formData.append(
      "details",
      JSON.stringify({
        type: "failuredocs",
        brand_id: submitData.brand_id,
        serial_id: submitData.serial_id,
      })
    );

    const uploadRes = await fileUploadWithRefresh(formData);
    if (uploadRes.status < 200 || uploadRes >= 300)
      return notify("Failed to upload file", { type: "error" });

    submitData.path = uploadRes.path;
    submitData.file_name = doc.title;
    delete submitData.document;

    // Create record
    create(
      "failures",
      { data: submitData },
      {
        onSuccess: (data) => {
          notify("New failures record is created.", { type: "success" });
          redirect("/failures");
        },
        onError: (data) => {
          notify("Failed to create failures record.", { type: "error" });
        },
      }
    );
  };

  return (
    <>
      <SimpleForm
        sanitizeEmptyValues
        onSubmit={handleSubmit}
        sx={{ maxWidth: 500 }}
      >
        <ReferenceInput reference="brands" source="brand_id">
          <SelectInput
            label="Brand"
            optionText="name"
            fullWidth
            validate={[required()]}
            onChange={handleBrandChange}
            create={
              accessControl.brandCreate ? <CreateBrandsOnSelectCreate /> : false
            }
            createLabel="-- Create --"
          />
        </ReferenceInput>
        <ReferenceInput
          reference="serials"
          source="serial_id"
          filter={{ brand_id: selectedBrand }}
        >
          <BrandModelFilterSelectInput
            label="Model"
            optionText="number"
            fullWidth
            validate={[required()]}
            create={
              accessControl.modelCreate ? (
                <CreateSerialsOnSelectCreate />
              ) : (
                false
              )
            }
            createLabel="-- Create --"
          />
        </ReferenceInput>
        <TextInput
          label="Failure Code"
          source="code"
          validate={validateFailureCode}
          isRequired
          fullWidth
        />
        <TextInput
          label="User Code"
          source="user_code"
          validate={validateUserCode}
          fullWidth
        />
        <TextInput
          label="Failure (Displayed on screen)"
          source="text"
          validate={validateFailureText}
          isRequired
          fullWidth
        />
        <TextInput
          label="Component in charge"
          source="component"
          validate={validateComponent}
          fullWidth
        />
        <TextInput
          label="Category"
          source="category"
          validate={validateCategory}
          fullWidth
        />
        <FileInput
          label="Document"
          source="document"
          validate={[required()]}
          fullWidth
        >
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </>
  );
};

const FailureCodeBulkForm = (props) => {
  const { isLoading, permissions } = usePermissions();
  const dataProvider = useDataProvider();
  const [selectedBrand, setSelectedBrand] = useState("");

  const notify = useNotify();
  const redirect = useRedirect();

  if (isLoading) return <></>;

  const accessControl = {
    brandCreate: acCanCreate("brands", permissions),
    modelCreate: acCanCreate("serials", permissions),
  };

  const handleSubmit = async (data) => {
    let uploadData = [];
    let submitData = { ...data };
    const details = JSON.stringify({
      type: "failuredocs",
      brand_id: submitData.brand_id,
      serial_id: submitData.serial_id,
    });

    // Parse the csv file
    const csvResult = await new Promise((resolve) => {
      parse(submitData.desc.rawFile, {
        header: true,
        complete: (results) => {
          resolve(results);
        },
      });
    });

    const csvData = csvResult.data.filter(
      (item) =>
        item.code != undefined && // eslint-disable-line eqeqeq
        item.user_code != undefined && // eslint-disable-line eqeqeq
        item.text != undefined && // eslint-disable-line eqeqeq
        item.component != undefined && // eslint-disable-line eqeqeq
        item.category != undefined && // eslint-disable-line eqeqeq
        item.file_name != undefined // eslint-disable-line eqeqeq
    );

    if (csvData.length <= 0)
      return notify("Empty or CSV file mismatch", { type: "error" });

    // Check file list
    const doc = submitData.document;
    const formData = new FormData();
    let fileList = [];

    if (Array.isArray(doc)) fileList = doc;
    else fileList.push(doc);

    // Check all files are selected?
    const allExist = csvData.every((c) =>
      fileList.some((f) => f.title === c.file_name)
    );

    if (!allExist) return notify("Missing file for upload", { type: "error" });

    // Pack upload formData
    fileList.forEach((f) => {
      formData.append("file", f.rawFile);
    });
    formData.append("details", details);

    const uploadRes = await fileUploadWithRefresh(formData);
    if (uploadRes.status < 200 || uploadRes >= 300)
      return notify("Failed to upload file", { type: "error" });

    if (uploadRes.files) {
      // map multiple files
      csvData.every((c) =>
        uploadRes.files.some((f) => {
          if (f.origFilename === c.file_name) {
            uploadData.push({
              brand_id: submitData.brand_id,
              serial_id: submitData.serial_id,
              ...c,
              path: f.path,
            });
            return true;
          }
          return false;
        })
      );
    } else {
      uploadData.push({
        brand_id: submitData.brand_id,
        serial_id: submitData.serial_id,
        ...csvData[0],
        path: uploadRes.path,
      });
    }

    // Create record
    try {
      const createPromises = uploadData.map((r) =>
        dataProvider.create("failures", { data: r })
      );

      await Promise.all(createPromises);

      notify("Records created", { type: "success" });
      redirect("/failures");
    } catch (error) {
      notify("Failed to create failures records", { type: "error" });
    }
  };

  const handleBrandChange = (e) => {
    if (e !== null && typeof e === "object") setSelectedBrand(e.target.value);
    else setSelectedBrand(e);
  };

  return (
    <>
      <SimpleForm
        sanitizeEmptyValues
        onSubmit={handleSubmit}
        sx={{ maxWidth: 500 }}
      >
        <ReferenceInput reference="brands" source="brand_id">
          <SelectInput
            label="Brand"
            optionText="name"
            fullWidth
            validate={[required()]}
            onChange={handleBrandChange}
            create={
              accessControl.brandCreate ? <CreateBrandsOnSelectCreate /> : false
            }
            createLabel="-- Create --"
          />
        </ReferenceInput>
        <ReferenceInput
          reference="serials"
          source="serial_id"
          filter={{ brand_id: selectedBrand }}
        >
          <BrandModelFilterSelectInput
            label="Model"
            optionText="number"
            fullWidth
            validate={[required()]}
            //onChange={handleModelChange}
            create={
              accessControl.modelCreate ? (
                <CreateSerialsOnSelectCreate />
              ) : (
                false
              )
            }
            createLabel="-- Create --"
          />
        </ReferenceInput>
        <FileInput
          label="Description"
          source="desc"
          validate={[required()]}
          fullWidth
        >
          <FileField source="src" title="title" />
        </FileInput>
        <FileInput
          label="Document"
          source="document"
          multiple
          validate={[required()]}
          fullWidth
        >
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </>
  );
};

const FailureCodeCreate = (props) => {
  const [formMode, setFormMode] = useState("single");

  const handleModeChange = (event, newValue) => {
    if (newValue === null) {
    } else {
      setFormMode(newValue);
    }
  };

  return (
    <Create title="Upload Document">
      <ToggleButtonGroup
        color="primary"
        value={formMode}
        exclusive
        onChange={handleModeChange}
        aria-label="Platform"
      >
        <ToggleButton value="single">Single</ToggleButton>
        <ToggleButton value="bulk">Bulk</ToggleButton>
      </ToggleButtonGroup>

      {formMode === "single" ? (
        <FailureCodeSingleForm />
      ) : (
        <FailureCodeBulkForm />
      )}
    </Create>
  );
};

export default FailureCodeCreate;
