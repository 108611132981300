import { IoDocument } from "react-icons/io5";

import ModelDocList from "./ModelDocList";
import ModelDocCreate from "./ModelDocCreate";

const resource = {
  label: "Document List",
  list: ModelDocList,
  create: ModelDocCreate,
  icon: IoDocument,
};

export default resource;
