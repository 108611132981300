import * as React from "react";
import {
  List,
  Datagrid,
  EmailField,
  TextField,
  ReferenceField,
  TopToolbar,
  CreateButton,
  ExportButton,
  SearchInput,
  usePermissions,
} from "react-admin";

import {
  acCanEdit,
  acCanCreate,
  acCanDelete,
} from "../components/accessControl";

const ListActions = () => (
  <TopToolbar>
    <CreateButton />
  </TopToolbar>
);

const pageFilters = [<SearchInput source="q" alwaysOn />];

const PageList = () => {
  const { isLoading, permissions } = usePermissions();

  if (isLoading) return <></>;

  const accessControl = {
    edit: acCanEdit("pages", permissions),
    create: acCanCreate("pages", permissions),
    delete: acCanDelete("pages", permissions),
  };

  return (
    <List
      sort={{ field: "name", order: "ASC" }}
      perPage={25}
      filters={pageFilters}
      actions={accessControl.create ? <ListActions /> : false}
    >
      <Datagrid
        rowClick={accessControl.edit ? "edit" : false}
        bulkActionButtons={accessControl.delete ? true : false}
      >
        <TextField label="Name" source="name" />
        <TextField label="Code" source="code" />
      </Datagrid>
    </List>
  );
};

export default PageList;
