import * as React from "react";
import {
  Edit,
  SimpleForm,
  PasswordInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  useUpdate,
  useRedirect,
  useNotify,
  usePermissions,
  Toolbar,
  SaveButton,
} from "react-admin";
import { Box, Typography } from "@mui/material";

import { acCanDelete } from "../components/accessControl";

const { validateName, validateCode } = require("./pageValidate");

const PageEdit = () => {
  const { isLoading, permissions } = usePermissions();
  const [update] = useUpdate();
  const redirect = useRedirect();
  const notify = useNotify();

  if (isLoading) return <></>;

  const accessControl = {
    delete: acCanDelete("pages", permissions),
  };

  const handleSubmit = (data) => {
    let newData = { ...data };

    update(
      "pages",
      {
        id: newData.id,
        data: newData,
      },
      {
        onSuccess: (data) => {
          notify("Update success", { type: "success" });
          redirect("/pages");
        },
        onError: (error) => {
          notify("Update failed", { type: "error" });
        },
      }
    );
  };

  const PagesEditToolbar = () => (
    <Toolbar>
      <SaveButton />
    </Toolbar>
  );

  return (
    <Edit title="Edit Page">
      <SimpleForm
        warnWhenUnsavedChanges
        onSubmit={handleSubmit}
        sx={{ maxWidth: 500 }}
        toolbar={accessControl.delete ? <Toolbar /> : <PagesEditToolbar />}
      >
        <Typography variant="h4" gutterBottom>
          Edit Page
        </Typography>
        <TextInput
          label="Name"
          source="name"
          validate={validateName}
          isRequired
          fullWidth
        />
        <TextInput
          label="Code"
          source="code"
          validate={validateCode}
          isRequired
          fullWidth
        />
      </SimpleForm>
    </Edit>
  );
};

export default PageEdit;
