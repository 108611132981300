import { BsPeopleFill } from "react-icons/bs";

import UserList from "./UserList";
import UserEdit from "./UserEdit";
import UserCreate from "./UserCreate";

const resource = {
  label: "Users List",
  list: UserList,
  create: UserCreate,
  edit: UserEdit,
  icon: BsPeopleFill,
};

export default resource;
