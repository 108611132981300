import React, { useState } from "react";
import {
  List,
  Datagrid,
  ReferenceField,
  TextField,
  WrapperField,
  TopToolbar,
  CreateButton,
  SearchInput,
  ReferenceInput,
  SelectInput,
  usePermissions,
} from "react-admin";

import { acCanCreate, acCanDelete } from "../components/accessControl";

import MyFileField from "../components/MyFileField";
import BrandModelFilterSelectInput from "../components/BrandModelFilterSelectInput";

const ListActions = () => (
  <TopToolbar>
    <CreateButton />
  </TopToolbar>
);

const ModelDocList = (props) => {
  const [selectedBrand, setSelectedBrand] = useState("");
  const { isLoading: isPermissionLoading, permissions } = usePermissions();

  if (isPermissionLoading) return <></>;

  const accessControl = {
    create: acCanCreate("docs", permissions),
    delete: acCanDelete("docs", permissions),
  };

  const modeldocFilters = [
    <SearchInput source="q" alwaysOn />,
    <ReferenceInput reference="brands" source="brand_id" alwaysOn>
      <SelectInput
        label="Brand"
        optionText="name"
        alwaysOn
        onChange={(e) => {
          setSelectedBrand(e.target.value);
        }}
      />
    </ReferenceInput>,
    <ReferenceInput
      id="serial"
      reference="serials"
      source="serial_id"
      filter={selectedBrand ? { brand_id: selectedBrand } : {}}
      alwaysOn
    >
      <BrandModelFilterSelectInput label="Model" optionText="number" alwaysOn />
    </ReferenceInput>,
    <ReferenceInput reference="doctypes" source="type_id" alwaysOn>
      <SelectInput label="Type" optionText="name" alwaysOn />
    </ReferenceInput>,
  ];

  return (
    <List
      sort={{ field: "serial_id", order: "ASC" }}
      perPage={25}
      filters={modeldocFilters}
      actions={accessControl.create ? <ListActions /> : false}
    >
      <Datagrid bulkActionButtons={accessControl.delete ? true : false}>
        <ReferenceField reference="brands" source="brand_id" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Model"
          reference="serials"
          source="serial_id"
          link={false}
        >
          <TextField source="number" />
        </ReferenceField>
        <ReferenceField reference="doctypes" source="type_id" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <WrapperField label="File Name">
          <MyFileField source="path" title="file_name" target="_blank" />
        </WrapperField>
      </Datagrid>
    </List>
  );
};

export default ModelDocList;
