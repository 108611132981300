import * as React from "react";
import { AppBar, TitlePortal } from "react-admin";
import { Box, useMediaQuery } from "@mui/material";
import MyUserMenu from "./MyUserMenu";

import Logo from "./Logo";

const CustomAppBar = () => {
  const isLargeEnough = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  return (
    <AppBar color="secondary" elevation={1} userMenu={<MyUserMenu />}>
      <TitlePortal />
      {isLargeEnough && <Logo />}
      {isLargeEnough && <Box component="span" sx={{ flex: 1 }} />}
    </AppBar>
  );
};

export default CustomAppBar;
