import React, { useState } from "react";
import {
  useCreate,
  useNotify,
  useRedirect,
  Create,
  SimpleForm,
  SelectInput,
  FileInput,
  FileField,
  required,
  ReferenceInput,
  usePermissions,
} from "react-admin";

import { fileUploadWithRefresh } from "../components/FileUploadWithRefresh";

import CreateBrandsOnSelectCreate from "../brands/CreateBrandsOnSelectCreate";
import CreateSerialsOnSelectCreate from "../serials/CreateSerialsOnSelectCreate";
import CreateDoctypesOnSelectCreate from "../doctypes/CreateDoctypesOnSelectCreate";
import BrandModelFilterSelectInput from "../components/BrandModelFilterSelectInput";
import { acCanCreate } from "../components/accessControl";

const ModelDocCreate = () => {
  const { isLoading, permissions } = usePermissions();
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedModel, setSelectedModel] = useState("");

  const [create] = useCreate();
  const notify = useNotify();
  const redirect = useRedirect();

  if (isLoading) return <></>;

  const accessControl = {
    brandCreate: acCanCreate("brands", permissions),
    modelCreate: acCanCreate("serials", permissions),
    doctypeCreate: acCanCreate("doctypes", permissions),
  };

  const handleSubmit = async (data) => {
    let modeldoc = { ...data };

    if (modeldoc.document) {
      const doc = modeldoc.document;
      const formData = new FormData();

      formData.append("file", doc.rawFile); // Add the file to the form data
      formData.append("details", JSON.stringify({ type: "modeldocs" }));

      const uploadRes = await fileUploadWithRefresh(formData);
      if (uploadRes.status < 200 || uploadRes.status >= 300)
        return notify("Failed to upload file", { type: "error" });

      modeldoc.path = uploadRes.path;
      modeldoc.file_name = doc.title;
      delete modeldoc.document;
    }

    create(
      "docs",
      { data: modeldoc },
      {
        onSuccess: (data) => {
          notify("Model document is created", { type: "success" });
          redirect("/docs");
        },
        onError: (data) => {
          notify("Failed to crearte model document", { type: "error" });
        },
      }
    );
  };

  const handleBrandChange = (e) => {
    if (e !== null && typeof e === "object") setSelectedBrand(e.target.value);
    else setSelectedBrand(e);
  };

  const handleModelChange = (e) => {
    if (e !== null && typeof e === "object") setSelectedModel(e.target.value);
    else setSelectedModel(e);
  };

  return (
    <Create title="Upload Document">
      <SimpleForm onSubmit={handleSubmit} sx={{ maxWidth: 500 }}>
        <ReferenceInput reference="brands" source="brand_id">
          <SelectInput
            label="Brand"
            optionText="name"
            fullWidth
            validate={[required()]}
            onChange={handleBrandChange}
            create={
              accessControl.brandCreate ? <CreateBrandsOnSelectCreate /> : false
            }
            createLabel="-- Create --"
          />
        </ReferenceInput>
        <ReferenceInput
          reference="serials"
          source="serial_id"
          filter={{ brand_id: selectedBrand }}
        >
          <BrandModelFilterSelectInput
            label="Model"
            optionText="number"
            fullWidth
            validate={[required()]}
            onChange={handleModelChange}
            create={
              accessControl.modelCreate ? (
                <CreateSerialsOnSelectCreate />
              ) : (
                false
              )
            }
            createLabel="-- Create --"
          />
        </ReferenceInput>
        <ReferenceInput reference="doctypes" source="type_id">
          <SelectInput
            label="Type"
            optionText="name"
            fullWidth
            validate={[required()]}
            create={
              accessControl.doctypeCreate ? (
                <CreateDoctypesOnSelectCreate />
              ) : (
                false
              )
            }
            createLabel="-- Create --"
          />
        </ReferenceInput>
        <FileInput label="Document" source="document" validate={[required()]}>
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};

export default ModelDocCreate;
