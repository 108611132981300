exports.acCanDelete = function (code, permissions) {
  if (permissions && permissions.role === "admin") return true;
  if (
    permissions === undefined ||
    typeof permissions !== "object" ||
    permissions.access === undefined ||
    typeof permissions.access !== "object" ||
    permissions.access[code] === undefined ||
    permissions.access[code].length === 0
  ) {
    return false;
  }
  return permissions.access[code].includes("delete");
};

exports.acCanCreate = function (code, permissions) {
  if (permissions && permissions.role === "admin") return true;
  if (
    permissions === undefined ||
    typeof permissions !== "object" ||
    permissions.access === undefined ||
    typeof permissions.access !== "object" ||
    permissions.access[code] === undefined ||
    permissions.access[code].length === 0
  ) {
    return false;
  }
  return ["create", "delete"].some((r) => permissions.access[code].includes(r));
};

exports.acCanEdit = function (code, permissions) {
  if (permissions && permissions.role === "admin") return true;
  if (
    permissions === undefined ||
    typeof permissions !== "object" ||
    permissions.access === undefined ||
    typeof permissions.access !== "object" ||
    permissions.access[code] === undefined ||
    permissions.access[code].length === 0
  ) {
    return false;
  }
  return ["edit", "create", "delete"].some((r) =>
    permissions.access[code].includes(r)
  );
};

exports.acCanShow = function (code, permissions) {
  if (permissions && permissions.role === "admin") return true;
  if (
    permissions === undefined ||
    typeof permissions !== "object" ||
    permissions.access === undefined ||
    typeof permissions.access !== "object" ||
    permissions.access[code] === undefined ||
    permissions.access[code].length === 0
  ) {
    return false;
  }
  return ["read", "edit", "create", "delete"].some((r) =>
    permissions.access[code].includes(r)
  );
};
