import React, { useState, useEffect } from "react";
import { SelectInput } from "react-admin";
import { useFormContext } from "react-hook-form";

const BrandModelFilterSelectInput = (props) => {
  const [selBrand, setSelBrand] = useState("");
  const [cnt, setCnt] = useState(0);
  const formContext = useFormContext();

  useEffect(() => {
    const formBrand = formContext.getValues("brand_id");
    const formModel = formContext.getValues("serial_id");

    // Reset model when brand changed
    // Don't reset for the 1st render
    if (cnt > 0 && formBrand !== selBrand && formModel !== "")
      formContext.setValue("serial_id", "");
    if (selBrand !== formBrand) setSelBrand(formBrand);
    setCnt((prevValue) => {
      return prevValue + 1;
    });
  }, [selBrand, setSelBrand, cnt, formContext]);

  return <SelectInput {...props} />;
};

export default BrandModelFilterSelectInput;
