import {
  Admin,
  fetchUtils,
  Resource,
  CustomRoutes,
  addRefreshAuthToDataProvider,
} from "react-admin";
import { Route } from "react-router-dom";
import jsonServerProvider from "ra-data-json-server";
import inMemoryJWTManager from "./inMemoryJwt";

import { authProvider, refreshAuth } from "./authProvider";
import { Login, Layout } from "./layout";
import { Dashboard } from "./dashboard";
import {
  acCanDelete,
  acCanCreate,
  acCanEdit,
  acCanShow,
} from "./components/accessControl";

// Resources related
import users from "./users";
import roles from "./roles";
import pages from "./pages";
import brands from "./brands";
import serials from "./serials";
import doctypes from "./doctypes";

import accesscontrol from "./accesscontrol";

import Profile from "./profile/Profile";
import modeldoc from "./modeldoc";
import failurecode from "./failurecode";

const httpClient = (url, options = {}) => {
  const token = inMemoryJWTManager.getToken();
  if (token) {
    options.user = {
      authenticated: true,
      token: `Bearer ${token}`,
    };
  }

  return fetchUtils.fetchJson(url, options);
};

const dataProvider = jsonServerProvider(
  process.env.REACT_APP_DATA_SOURCE,
  httpClient
);

const dataProviderWithRefresh = addRefreshAuthToDataProvider(
  dataProvider,
  refreshAuth
);

const App = () => {
  const CustomResource = (resourceName, permissions, obj) => {
    let op = {};
    if (acCanShow(resourceName, permissions) && obj.list) op["list"] = obj.list;
    if (acCanEdit(resourceName, permissions) && obj.edit) op["edit"] = obj.edit;
    if (
      (acCanCreate(resourceName, permissions) ||
        acCanDelete(resourceName, permissions)) &&
      obj.create
    )
      op["create"] = obj.create;

    return <Resource name={resourceName} {...op} />;
  };

  return (
    <Admin
      authProvider={authProvider}
      dataProvider={dataProviderWithRefresh}
      dashboard={Dashboard}
      loginPage={Login}
      layout={Layout}
    >
      {(permissions) => (
        <>
          <CustomRoutes>
            <Route path="/profile" element={<Profile />} />
          </CustomRoutes>

          {CustomResource("docs", permissions, modeldoc)}
          {CustomResource("failures", permissions, failurecode)}
          {CustomResource("brands", permissions, brands)}
          {CustomResource("serials", permissions, serials)}
          {CustomResource("doctypes", permissions, doctypes)}
          {CustomResource("users", permissions, users)}
          {CustomResource("roles", permissions, roles)}
          {CustomResource("pages", permissions, pages)}
          {CustomResource("accesscontrol", permissions, accesscontrol)}

          <Resource name="accesscontrol" list={accesscontrol.list} />
        </>
      )}
    </Admin>
  );
};

export default App;
