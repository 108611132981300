import React, { useMemo, useEffect } from "react";
import { useGetIdentity, usePermissions, useRedirect } from "react-admin";
import { useNavigate } from "react-router-dom";

import { useMediaQuery, useTheme } from "@mui/material";
import { subDays, startOfDay } from "date-fns";

import inMemoryJWTManager from "../inMemoryJwt";

import Welcome from "./Welcome";
import LoadingSpinner from "../components/LoadingSpinner";

const styles = {
  flex: { display: "flex" },
  flexColumn: { display: "flex", flexDirection: "column" },
  leftCol: { flex: 1, marginRight: "0.5em" },
  rightCol: { flex: 1, marginLeft: "0.5em" },
  singleCol: { marginTop: "1em", marginBottom: "1em" },
};

//const Spacer = () => <div style={{ width: "1em" }} />;
const VerticalSpacer = () => <div style={{ height: "1em" }} />;

const DashboardReal = (props) => {
  const { profile } = props;
  const theme = useTheme();
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const aMonthAgo = useMemo(() => subDays(startOfDay(new Date()), 30), []);
  const threeMonthAgo = useMemo(() => subDays(startOfDay(new Date()), 90), []);

  const token = inMemoryJWTManager.getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  return isXSmall ? (
    <div>
      <div style={styles.flexColumn}>
        <Welcome />
        <VerticalSpacer />
        <VerticalSpacer />
        <VerticalSpacer />
      </div>
    </div>
  ) : isSmall ? (
    <div>
      <div style={styles.flexColumn}>
        <Welcome />
        <div style={styles.flex}>
          <div style={styles.leftCol}></div>
          <div style={styles.rightCol}></div>
        </div>
        <VerticalSpacer />
        <div></div>
        <VerticalSpacer />
        <div></div>
      </div>
    </div>
  ) : (
    <>
      <Welcome />
      <div style={styles.flex}>
        <div style={styles.leftCol}></div>
        <div style={styles.rightCol}></div>
      </div>
      <VerticalSpacer />
      <div></div>
      <VerticalSpacer />
      <div></div>
    </>
  );
};

const Dashboard = () => {
  const navigate = useNavigate();

  /*
  // Switch to Failure Code page now
  useEffect(() => {
    navigate("/failures");
  }, []);
*/

  // original flow
  const { isLoading: isLoadingPermissions, permissions } = usePermissions();
  const { data: identity, isLoading: isLoadingIdentity } = useGetIdentity();

  if (isLoadingPermissions || isLoadingIdentity) return <LoadingSpinner />;
  return (
    <DashboardReal
      profile={{ userId: identity.id, permissions: permissions }}
    />
  );
};
export default Dashboard;
