import React from "react";
import { Default } from "react-awesome-spinners";

const LoadingSpinner = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#f3f3f3",
      width: "100%",
      height: "100%",
    }}
  >
    <Default />
  </div>
);

export default LoadingSpinner;
