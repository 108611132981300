import React from "react";
import {
  useCreate,
  useCreateSuggestionContext,
  ReferenceInput,
  SelectInput,
  required,
  Form,
  TextInput,
} from "react-admin";

import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";

const { validateSerialNumber } = require("../serials/serialValidate");

const CreateSerialsOnSelectCreate = () => {
  const { onCancel, onCreate } = useCreateSuggestionContext();
  const [create] = useCreate();

  const handleSubmit = (data) => {
    let serial = { ...data };

    create(
      "serials",
      {
        data: serial,
      },
      {
        onSuccess: (data) => {
          onCreate(data);
        },
      }
    );
  };

  return (
    <Dialog open onClose={onCancel}>
      <Form onSubmit={handleSubmit}>
        <DialogContent>
          <ReferenceInput reference="brands" source="brand_id">
            <SelectInput
              label="Brand"
              optionText="name"
              validate={[required()]}
              sx={{ maxWidth: 500 }}
            />
          </ReferenceInput>
          <br />
          <TextInput
            label="New model name"
            source="number"
            validate={validateSerialNumber}
            autoFocus
            sx={{ maxWidth: 500 }}
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">Save</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default CreateSerialsOnSelectCreate;
