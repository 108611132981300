import { refreshAuth } from "../authProvider";
import inMemoryJwt from "../inMemoryJwt";

const fileUploadWithRefresh = async (formData) => {
  try {
    refreshAuth();

    const token = inMemoryJwt.getToken();

    const uploadRes = await fetch(
      process.env.REACT_APP_DATA_SOURCE + "/upload",
      {
        method: "POST",
        body: formData,
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (uploadRes.status < 200 || uploadRes.status >= 300)
      return { status: uploadRes.status };

    const uploadData = await uploadRes.json();
    return { status: 200, ...uploadData };
  } catch (error) {
    return { status: 500 };
  }
};

export { fileUploadWithRefresh };
