import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  useCreate,
  useNotify,
  useRedirect,
  required,
  minLength,
  maxLength,
} from "react-admin";
import { Box, Typography } from "@mui/material";

const { validateDoctypeName } = require("./doctypeValidate");

const DoctypeCreate = () => {
  const [create] = useCreate();
  const notify = useNotify();
  const redirect = useRedirect();

  const handleSubmit = (data) => {
    let doctype = { ...data };

    create(
      "doctypes",
      { data: doctype },
      {
        onSuccess: (data) => {
          notify("Doc Type is created", { type: "success" });
          redirect("/doctypes");
        },
        onError: (error) => {
          notify("Failed to create doc type", { type: "error" });
        },
      }
    );
  };

  return (
    <Create title="Create Doc Type">
      <SimpleForm
        warnWhenUnsavedChanges
        onSubmit={handleSubmit}
        sx={{ maxWidth: 500 }}
      >
        <Typography variant="h4" gutterBottom>
          Create Doc Type
        </Typography>
        <TextInput
          label="Name"
          source="name"
          autoFocus
          isRequired
          validate={validateDoctypeName}
          fullWidth
        />
      </SimpleForm>
    </Create>
  );
};

export default DoctypeCreate;
